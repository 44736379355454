import saveAs from 'file-saver';
import { useCallback, useState } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { errorActions } from '../actions';
import { DownloadFile } from '../types/amr-pipeline/models/DownloadedDocument';
import { Nullable } from '../types/primitives/Nullable';

interface Props<TArgs extends any[]> {
    downloadRequest: (...args: TArgs) => Promise<DownloadFile>;
    requestArgs: TArgs;
    fileNameForSave: Nullable<string>;
}

export const useDownloadDocuments = <TArgs extends any[]>({
    downloadRequest,
    requestArgs,
    fileNameForSave,
}: Props<TArgs>) => {
    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = useCallback(downloadHandler, [
        downloadRequest,
        requestArgs,
        fileNameForSave,
        dispatch,
    ]);

    async function downloadHandler() {
        setIsDownloading(true);

        try {
            const file = await downloadRequest(...requestArgs);

            saveAs(file.blob, fileNameForSave ? `${fileNameForSave}` : file.name);
        } catch (e) {
            dispatch(errorActions.error(e));
        } finally {
            setIsDownloading(false);
        }
    }

    return { isDownloading, handleDownload };
};
